body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  color: #585021;
}
* {
  box-sizing: border-box;
}
:focus {
  outline: thin dotted;
}
h1,
h2,
h3,
h4 {
  font-family: 'Parisienne', cursive;
}
h1 {
  font-size: 3rem;
  font-weight: 100;
  margin-top: 0;
}
h2 {
  font-size: 3rem;
}
.mt-2 {
  margin-top: 2rem;
}
.main {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-child {
  -ms-flex-preferred-size: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}

.reveal-result {
  flex-direction: column;
  padding-bottom: 4rem;
  z-index: 99;
  position: relative;
}
.result-card {
  width: 100%;
  align-items: baseline;
  padding: 2rem 6rem;
}
.result-card .flex {
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.result-card p {
  flex-basis: 70%;
}
.result-card img {
  height: 50vh;
}
.result-card p {
  padding: 0 2rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.reveal h1 {
  color: #fff;
  font-size: 4rem;
  font-weight: bolder;
  line-height: 2.5rem;
}
.reveal .backgound p {
  color: #fff;
  font-size: 1.5rem;
}

.home {
  padding: 2rem;
}
.home h1 {
  font-size: 2.5rem;
}
.card {
  height: 250px;
  flex-grow: 0;
  max-width: 155px;
  margin: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.home {
  background-image: url(https://hanasaludybienestar.com/wp-content/uploads/sites/11/2020/05/juego-cartas-flores-500x375-1.jpg);
  background-size: cover;
  background-position: center;
  text-align: center;
}
.cartas {
  background-image: url(https://hanasaludybienestar.com/wp-content/uploads/sites/11/2020/06/macro-shot-of-wooden-planks-326333.jpg);
  background-size: cover;
  background-position: center;
  padding: 1rem;
}
.reveal {
  background: #fafdec;
}
.reveal .backgound:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: -moz-linear-gradient(180deg, rgba(1, 1, 3, 0) 63%, #fafdec 100%);
  background: -webkit-linear-gradient(180deg, rgba(1, 1, 3, 0) 63%, #fafdec 100%);
  background: linear-gradient(180deg, rgba(1, 1, 3, 0) 63%, #fafdec 100%);
}
.reveal .backgound {
  background-image: url(https://hanasaludybienestar.com/wp-content/uploads/sites/11/2020/05/ray-hennessy-HlJ7U9WHRR8-unsplash.jpg);
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  padding: 5rem 0;
}

.whiteBg {
  background: #fff;
  padding: 2rem;
  box-shadow: 4px 4px 12px 1px rgba(0, 0, 0, 0.3607843137254902);
  border-radius: 20px;
}
input[type='text'] {
  padding: 1rem;
  font-size: 1.3rem;
  width: 100%;
}
.button {
  transition: all 0.5s ease;
  border-radius: 60px;
  text-shadow: none;
  background-color: #ebe2c3;
  border: solid 1px #585021;
  box-shadow: 2px 2px 13px rgba(70, 70, 70, 0.2);
  color: #585021;
  padding: 1rem;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  width: 170px;
}
.button:hover {
  background-color: #585021;
  border: solid 1px #585021;
  color: white;
  letter-spacing: 0.05rem;
}
.cta {
  right: 15px;
  bottom: 15px;
  position: fixed;
  font-size: 2.5rem;
  line-height: 1.5rem;
  text-align: center;
  padding: 2rem;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  z-index: 99;
}
.see-product {
  display: block;
  font-size: 1.2rem;
  color: #1a7bb3;
  text-decoration: none;
}
.see-product:hover {
  text-decoration: underline;
}

@media (max-width: 700px) {
  .card {
    height: 150px;
  }
  .result-card {
    align-items: center;
    padding: 2rem;
  }
  .result-card .flex {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  body .cartas {
    background: #291507;
    background: -moz-linear-gradient(180deg, rgba(84, 44, 20, 1) 41%, rgba(29, 15, 5, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(84, 44, 20, 1) 41%, rgba(29, 15, 5, 1) 100%);
    background: linear-gradient(180deg, rgba(84, 44, 20, 1) 41%, rgba(29, 15, 5, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#542c14",endColorstr="#1d0f05",GradientType=1);
  }
  .result-card p {
    text-align: center;
  }
  .result-card h2 {
    margin-top: 0;
  }
  .cta {
    padding: 0;
    right: 5px;
    bottom: 1rem;
    font-size: 2rem;
    text-align: right;
  }
  .reveal .cta {
    opacity: 0;
    transition: all 0.4s ease;
  }
  .cta.show {
    opacity: 1;
  }
}
